<template>
	<form
		class="form-create-rate main-wrapper mt-4"
		@submit.prevent="handleSubmit"
	>
		<CRow class="mb-5">
			<CCol lg="12">
				<div class="form-group d-flex">
					<label for="shipping-fee">Shipping fee</label>
					<BaseInputNumber
						id="shipping-fee"
						v-model="$v.fee.$model"
						:decimal-scale="2"
						:is-valid="!$v.fee.$error"
						:invalid-feedback="feeErrors"
						prepend-text="฿"
						text-align="right"
						placeholder="0.00"
					/>
				</div>
				<hr class="mb-4">
			</CCol>
			<CCol lg="12">
				<h4 class="mb-4">
					Cart total
				</h4>
			</CCol>
			<CCol lg="12">
				<div class="form-group form-group-cart d-flex">
					<label for="price">Price</label>
					<BaseInputNumber
						id="price"
						v-model="$v.minPrice.$model"
						:decimal-scale="2"
						:is-valid="!$v.minPrice.$error"
						:invalid-feedback="$t('global.error.required')"
						prepend-text="฿"
						text-align="right"
						placeholder="0.00"
					/>
					<span class="to">to</span>
					<BaseInputNumber
						v-model="$v.maxPrice.$model"
						:decimal-scale="2"
						:is-valid="!$v.maxPrice.$error"
						:invalid-feedback="$t('global.error.required')"
						prepend-text="฿"
						text-align="right"
						placeholder="0.00"
					/>
				</div>
			</CCol>
			<CCol lg="12">
				<div class="form-group form-group-cart d-flex">
					<label for="weight">Weight(kg)</label>
					<BaseInputNumber
						id="weight"
						v-model="$v.minWeight.$model"
						:is-valid="!$v.minWeight.$error"
						:invalid-feedback="$t('global.error.required')"
						text-align="right"
						placeholder="0"
					/>
					<span class="to">to</span>
					<BaseInputNumber
						v-model="$v.maxWeight.$model"
						:is-valid="!$v.maxWeight.$error"
						:invalid-feedback="$t('global.error.required')"
						text-align="right"
						placeholder="99"
					/>
				</div>
			</CCol>
			<CCol lg="12">
				<div class="form-group form-group-cart d-flex">
					<label for="quantity">Quantity</label>
					<BaseInputNumber
						id="quantity"
						v-model="$v.minQuantity.$model"
						:is-valid="!$v.minQuantity.$error"
						:invalid-feedback="$t('global.error.required')"
						text-align="right"
						placeholder="0"
					/>
					<span class="to">to</span>
					<BaseInputNumber
						v-model="$v.maxQuantity.$model"
						:is-valid="!$v.maxQuantity.$error"
						:invalid-feedback="$t('global.error.required')"
						text-align="right"
						placeholder="99"
					/>
				</div>
			</CCol>
		</CRow>

		<BaseActionPanelStickyFooter
			:disabled-confirm="createRate.isCreating"
			content-class="main-wrapper"
			@onConfirm="handleSubmit"
			@onCancel="$router.push({ name: 'ShippingSetting'})"
		/>
	</form>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { required, maxValue } from 'vuelidate/lib/validators';
import { pathOr, priceToAPI } from '../assets/js/helpers';
import { MAXIMUM_FEE } from '../enums/shippings';

export default {
	name: 'ShippingRateCreate',
	validations: {
		fee: {
			required,
			maxValue: maxValue(MAXIMUM_FEE),
		},
		minPrice: {
			required,
		},
		maxPrice: {
			required,
		},
		minWeight: {
			required,
		},
		maxWeight: {
			required,
		},
		minQuantity: {
			required,
		},
		maxQuantity: {
			required,
		},
	},
	data() {
		return {
			shippingMethodId: this.$route.params.shippingMethodId,
			fee: null,
			minPrice: null,
			maxPrice: null,
			minWeight: null,
			maxWeight: null,
			minQuantity: null,
			maxQuantity: null,
		};
	},
	computed: {
		...mapState('shippings', {
			createRate: (state) => state.shippingRates.create,
		}),

		feeErrors() {
			if (!this.$v.fee.required) {
				return this.$t('global.error.required');
			}

			if (!this.$v.fee.maxValue) {
				return 'Please input the maximum number 99,999,999.99';
			}

			return null;
		},
	},
	methods: {
		...mapActions({
			showToast: 'toast/showToast',
			postShippingRate: 'shippings/postShippingRate',
		}),

		async handleSubmit() {
			this.$v.$touch();

			if (this.$v.$invalid) {
				return;
			}

			try {
				const params = {
					fee: priceToAPI(this.fee),
					min_price: priceToAPI(this.minPrice),
					max_price: priceToAPI(this.maxPrice),
					min_weight: this.minWeight,
					max_weight: this.maxWeight,
					min_quantity: this.minQuantity,
					max_quantity: this.maxQuantity,
				};

				await this.postShippingRate({
					id: this.shippingMethodId,
					params,
				});

				this.showToast({
					type: 'success',
					header: this.$t('global.successMessageTitle'),
					content: this.$t('global.successMessageCreate', { type: 'rule' }),
				});

				// Redirect to payment setting page
				this.$router.push({ name: 'ShippingSetting' });
			} catch (error) {
				const errorMessages = [];
				const errors = pathOr(null, ['response', 'data', 'errors'])(error);

				if (errors) {
					Object.keys(errors).forEach((key) => errorMessages.push(errors[key][0]));

					this.showToast({
						type: 'danger',
						header: this.$t('global.errorMessageTitleCreate'),
						content: errorMessages,
					});
				}
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	.form-group {
		label {
			flex: 1;
			max-width: rem(160);
			margin-bottom: 0;
			position: relative;
			top: 8px;
		}

		.to {
			@include typo-body-2;

			position: relative;
			top: 8px;
			margin: 0 rem(24);
		}


		// .form-group-cart
		&-cart {
			margin-bottom: rem(32);
		}

		::v-deep .input-group {
			max-width: rem(140);
		}
	}

	.btn-submit {
		min-width: auto;
	}
</style>
